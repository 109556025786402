import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout";
import ProductAreaHeader from "gatsby-theme-rothenberger/src/components/blocks/ProductAreaHeader";
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper";
import ProductCard from "gatsby-theme-rothenberger/src/components/molecules/ProductCard";
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext";
import { productAreaImageMapping } from "../../themes/gatsby-theme-rothenberger/config/imageMappings";

import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo";

const ProductAreaTemplate = ({ data }) => {
  const entry = data.entry;
  const products = data.products.edges;
  const productLines = data.productLines.edges;
  const imageMapping = productAreaImageMapping[parseInt(entry?.ID)] || null

  const wideHeaderImagePath = imageMapping?.wide || null
  const normalHeaderImagePath = imageMapping?.normal || null

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
  ];

  return (
    <Layout language={entry.language}>
      <SEO
        title={`${entry.name} | ${process.env.GATSBY_PRODUCT_AREAS_BASE}`}
        no_description_fallback={true}
        og_image={normalHeaderImagePath}
        language={entry.language}
      />
      <ProductAreaHeader
        title={entry?.name}
        normalImage={normalHeaderImagePath}
        wideImage={wideHeaderImagePath}
        breadcrumbs={breadcrumbs}
      />

      <section className="py-block">
        <div className=" block-w-container">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full mb-8 md:w-1/3 lg:w-1/4 px-grid">
              <div className="py-4 border border-gray-100">
                {productLines.map((group, index) => {
                  return (
                    <div key={group.node.fullSlug} className="block">
                      <Link
                        className="inline-block py-2 text-sm px-grid hover:text-primary"
                        key={index}
                        to={group.node.fullSlug}
                      >
                        {group.node.name}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full md:w-2/3 lg:w-3/4 px-grid">
              <div className="flex flex-row flex-wrap -mx-grid">
                {products.map((product) => (
                  <div
                    className="flex w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
                    key={product.node.fullSlug}
                  >
                    <ProductCard
                      item={{
                        headline: product.node.name,
                        text: product.node.properties.groupName,
                        image: product.node.properties.mainImage,
                        treeSorting: product.node.treeSorting,
                        link: product.node.fullSlug,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {entry?.properties?.description?.length > 0 && (
        <section>
          <div className="pb-block block-w-container">
            <div className="flex flex-row flex-wrap -mx-grid">
              <div className="w-full lg:w-1/4 px-grid "></div>
              <div className="w-full lg:w-2/4 px-grid ">
                <Richtext text={entry?.properties?.description} />
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query AreaQuery($id: String, $areaId: String, $locale: String) {
    entry: contentServProductArea(ID: { eq: $id }, locale: { eq: $locale }) {
      name
      externalKey
      language
      ID
      fullSlug
      rootPage {
        name
        fullSlug
      }
      properties {
        description
        mainImage
      }
    }
    products: allContentServProduct(
      filter: { areaId: { eq: $areaId }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          areaId
          groupId
          ID
          treeSorting
          fullSlug
          parentId
          externalKey
          properties {
            mainImage
            media
            groupName
          }
        }
      }
    }
    productLines: allContentServProductLine(
      filter: { areaId: { eq: $areaId }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          lineId
          ID
          fullSlug
          externalKey
        }
      }
    }
  }
`;

export default ProductAreaTemplate;
